import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import serviceImg_1 from '../Assets/Images/1.jpg';
import serviceImg_2 from '../Assets/Images/2.jpg';
import serviceImg_3 from '../Assets/Images/3.jpg';
import serviceImg_4 from '../Assets/Images/4.jpg';
import serviceImg_5 from '../Assets/Images/5.jpg';
import serviceImg_6 from '../Assets/Images/6.jpg';

class Service extends Component {
    render () {
        return (
            <div id="service" className="hx-service-area ptb-100-70">
                <div className="container">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <span>What I Do</span>
                            <h2>My Services</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img src={serviceImg_1} alt='' loading="lazy"/>
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Web Design</h3>
                                        <p>Designing visually appealing layouts</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img src={serviceImg_2} alt='' loading="lazy"/>
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Web Development</h3>
                                        <p>Developing complex web applications that provide specific functionality</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img src={serviceImg_3} alt='' loading="lazy"/>
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>SEO</h3>
                                        <p>Implementing strategies to improve a website's visibility on search engines</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img src={serviceImg_4} alt='' loading="lazy"/>
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Website Maintenance</h3>
                                        <p>Regularly updating and maintaining websites to ensure they remain functional, secure, and up-to-date with the latest technologies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img src={serviceImg_5} alt='' loading="lazy"/>
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Visuals</h3>
                                        <p>Motion Video Visuals</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 grid col-12">
                            <div className="hx-service-item">
                                <div className="hx-service-single">
                                    <div className="service-img">
                                        <img src={serviceImg_6} alt='' loading="lazy"/>
                                    </div>
                                    <div className="hx-service-content">
                                        <h3>Photo Edits</h3>
                                        <p>Flyers, Mixtape Covers, Business Cards, Social Banners Covers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Service;