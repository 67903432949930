import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Gallery_1 from '../Assets/Images/gallery/img-1.png';
import Gallery_2 from '../Assets/Images/gallery/img-2.png';
import Gallery_3 from '../Assets/Images/gallery/img-3.png';
import Gallery_4 from '../Assets/Images/gallery/img-4.png';
import Gallery_5 from '../Assets/Images/gallery/img-5.png';
import Gallery_6 from '../Assets/Images/gallery/img-6.png';
import Gallery_7 from '../Assets/Images/gallery/img-7.png';
import Gallery_8 from '../Assets/Images/gallery/img-8.png';
import Gallery_9 from '../Assets/Images/gallery/img-9.png';
import Gallery_10 from '../Assets/Images/gallery/img-10.png';
import Gallery_11 from '../Assets/Images/gallery/img-1-gd.png';



class Porfolio extends Component {

    state = {
        onWebDesign: false,
        onPrintDesign: false,
        onWebApp: false,
        onPhotography: false,
        isOpen : false,
    }

    render() {

        const allElement = event => {
            event.preventDefault();
            this.setState({
                onWebApp: true,
                onPrintDesign: true,
                onWebDesign: true,
                onPhotography: true,
            });
        }

        const webDesign = event => {
            event.preventDefault();
            this.setState({
                onWebDesign: true,
                onPrintDesign: false,
                onWebApp: false,
                onPhotography: false,
                isOpen: true,
            });
        }

        const Demos = event => {
            event.preventDefault();
            this.setState({
                onDemos: true,
                onWebApp: false,
                onPrintDesign: false,
                onWebDesign: false,
                isOpen: true,
            });
        }

        const printDesign = event => {
            event.preventDefault();
            this.setState({
                onPrintDesign: true,
                onWebDesign: false,
                onWebApp: false,
                onPhotography: false,
                isOpen: true,
            });
        }





        console.log(this.state.onWebDesign);

        return (
            <section id="protfolio" className="gallery-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12 sortable-gallery">
                            <div className="gallery-filters">
                                <div className="section-title">
                                    <span>Portfolio</span>
                                    <h2>Work I Have Done</h2>
                                </div>
                                <ul>
                                    <li><a data-filter="*" href="#" className="current" onClick={allElement}>All</a></li>
                                    <li><a data-filter=".Web-Design" href="#" onClick={webDesign}>Web Design</a></li>
                                    <li><a data-filter=".Demos" href="#" onClick={Demos}>Demos</a></li>
                                    <li><a data-filter=".Print-Design" href="#" onClick={printDesign}>Print Design</a></li>
                                </ul>
                            </div>
                            <div className="gallery-container gallery-fancybox masonry-gallery tumho-masonary">

                            <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"
                                    }>
                                   <img src={Gallery_10} className="img img-responsive" alt="" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://kintosol.com" target="_blank" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>
                               
                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"
                                    }>
                                   <img src={Gallery_1} className="img img-responsive" alt="" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://www.masteredtrax.com" target="_blank" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onWebDesign ? "grid active" : "grid" 
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_2} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://rxzrmusic.com" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_6} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://masteredtrax.shop" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_5} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://virusenterprises.com" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_3} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://ckan.mx" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_4} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://mobclothing.com.mx" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onWebDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_7} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://www.goodluckmarys.com" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onDemos ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_8} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://eugenioespinoza.com/demos/tacotruckv2" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onDemos ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_9} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="https://eugenioespinoza.com/snowthaproduct/" target="_blank" rel="noopener noreferrer" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                <div className={this.state.onPrintDesign ? "grid active" : "grid"
                                    && this.state.isOpen ? "grid" : "grid open"}>
                                    <img src={Gallery_11} alt="" className="img img-responsive" loading="lazy"/>
                                    <div className="icon">
                                        <a href="#" target="_blank" className="view-icon">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </a>
                                    </div>
                                </div>

                                

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Porfolio;