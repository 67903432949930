import React, { Component } from 'react';
import { faAccessibleIcon, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slides from '../Assets/Images/slide-2.jpg';

class Banner extends Component {
    render() {
        return(
            <section id="home" className="hero hero-slider-wrapper hero-style-1">
                <div className="hero-slider">
                    <div className="slide">
                        <div className="slider-image">
                            <img src={Slides}/>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-subtitle">
                                        <h4>Eugenio Espinoza</h4>
                                    </div>
                                    <div className="slide-title">
                                        <h2>Web Developer</h2>
                                    </div>
                                    <div className="btns">
                                        <a href="https://www.instagram.com/webdev_eesp/" className="theme-btn go-contact-area" data-translate="Letswork">Let's Work</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="copy"><p>Web Developer And Graphic Designer</p></div> */}
                <div className="social-links">
                    <ul>
                        <li>
                            <a href="https://www.instagram.com/webdev_eesp/">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                        <li>
                            <a href="https://github.com/egitweb">
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                        </li>
                       
                    </ul>
                </div>
            </section>
        )
    }
}

export default Banner;