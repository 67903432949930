import React, { Component } from 'react';

class PricingPlan extends Component {
    render() {
        return (
            <div id="pricing" className="pricing-area ptb-100-70">
                <div className="container">
                    <div className="section-title text-center">
                        <span>I Give You The Best</span>
                        <h2>my Pricing Plan</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-l2 col-md-4 col-sm-6 grid p-out">
                                    <div className="price-item">
                                        <div className="pricing-header">
                                            <h3>Basic</h3>
                                        </div>
                                        <div className="pricing-table">
                                            <div className="price-sub-header pricing-header-c">
                                                <h4><span>$</span>700</h4>
                                                <h5></h5>
                                            </div>
                                            <ul>
                                                <li>Web Design</li>
                                                <li>Responsive Design</li>
                                                <li>Up to 4 Pages</li>
                                                <li>Hosting & SSL Encryption Set Up</li>
                                                <li>Content Updates</li>
                                                <li>Maintenance</li>
                                                <p>Content Updates and Maintenance is $10 per month.</p>
                                            </ul>
                                            <div className="pricing-footer">
                                                <div className="theme-btn"><a href="https://ig.me/m/webdev_eesp/">Let's Talk</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-l2 col-md-4 col-sm-6  grid p-out">
                                    <div className="price-item price-item-2">
                                        <div className="pricing-header">
                                            <h3>Standard</h3>
                                        </div>
                                        <div className="pricing-table pricing-table-2">
                                            <div className="price-sub-header">
                                                <h4><span>$</span>1,000</h4>
                                                <h5></h5>
                                            </div>
                                            <ul>
                                                <li>Web Design</li>
                                                <li>Responsive Design</li>
                                                <li>Up to 10 Pages</li>
                                                <li>Hosting & SSL Encryption Set Up</li>
                                                <li>Content Updates</li>
                                                <li>Maintenance</li>
                                            </ul>
                                            <p>Content Updates and Maintenance is $10 per month.</p>
                                            <div className="pricing-footer pricing-footer-2">
                                                <div className="theme-btn"><a href="https://ig.me/m/webdev_eesp/">Let's Talk</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-l2 col-md-4 col-sm-6 grid  p-out p-out-t">
                                    <div className="price-item price-item-3">
                                        <div className="pricing-header">
                                            <h3>Premium</h3>
                                        </div>
                                        <div className="pricing-table">
                                            <div className="price-sub-header pricing-header-b">
                                                <h4><span>$</span>1400</h4>
                                                <h5>E-Commerce</h5>
                                            </div>
                                            <ul>
                                            <li>Web Design</li>
                                                <li>Responsive Design</li>
                                                <li>Up to 10 Pages</li>
                                                <li>Hosting & SSL Encryption Set Up</li>
                                                <li>Shopify | Wordpress</li>
                                                <li>Content Updates</li>
                                                <li>Maintenance</li>
                                            </ul>
                                            <p>Content Updates and Maintenance is $30 per month.</p>
                                            <p></p>
                                            <div className="pricing-footer">
                                                <div className="theme-btn"><a href="https://ig.me/m/webdev_eesp/">Let's Talk</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PricingPlan;