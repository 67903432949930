import React, { Component } from 'react';
import aboutImg from '../Assets/Images/about.png';

class About extends Component {
    render() {
        return (
            <div className="hx-about-style-1" id="about">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 col-lg-6 d-xl-flex d-lg-flex d-block align-items-center">
                            <div className="hx-about-content">
                                <div className="hx-site-title">
                                    <span>Full Stack Developer</span>
                                    <h2>About Me</h2>
                                </div>
                                <p>👋 Hey there! <br></br> I'm a 27-year-old web developer from Minnesota driven by a tech obsession since the iPhone Jailbreak era. 
                                    High school ignited my coding passion, and I jumped into a University of Minnesota coding bootcamp, 
                                    emerging as a certified full stack developer.
                                   </p>
                                <p>Let's collaborate to bring your concepts to life, 
                                    creating immersive digital experiences that leave a lasting impression on your viewers.🎉</p>
                                {/* <div className="btns">
                                    <a href="#" className="theme-btn">Download CV</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5">
                            <div className="hx-about-img">
                                <img src={aboutImg} loading="lazy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;